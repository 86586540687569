// import Vue from "vue";
// import Vuex from "vuex";
// Vue.use(Vuex);
// const store = new Vuex.Store({
//   state: {
//     operatorId:"", 
//   },
//   // getters:{

//   // },
//   mutations: {
//   },
//   actions: {
//   },
//   // modules: {
//   // }
// });
// // })
// export default store;
import { createStore } from 'vuex'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
