import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/layout',
    name: 'layout', // 路由名称
    // component: resolve =>require(['@/views/Layout'],resolve), // 组件对象
    component: () => import(/*webpackChunkName:"name"*/'@/views/Layout'),
    redirect: '/layout/homepage',
    children: [
      //首页
      {
        path: '/',
        name: 'homepage',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/homepage')
        // component:resolve =>require(['@/views/main/homepage'],resolve)
      },
      {
        path: 'execDir',
        name: 'execDir',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/execDir')
        // component:resolve =>require(['@/views/main/homepage'],resolve)
      },
      {
        path: 'legalSup',
        name: 'legalSup',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/legalSup')
        // component:resolve =>require(['@/views/main/homepage'],resolve)
      },
      {
        path: 'newsTrends',
        name: 'newsTrends',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/newsTrends')
        // component:resolve =>require(['@/views/main/homepage'],resolve)
      },
      {
        path: 'moreContact',
        name: 'moreContact',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/moreContact')
        // component:resolve =>require(['@/views/main/homepage'],resolve)
      },
      {
        path: 'compProfile',
        name: 'compProfile',
        component: () => import(/*webpackChunkName:"name"*/'@/views/main/compProfile')
        // component:resolve =>require(['@/views/main/homepage'],resolve)
      },
      {
        path: 'newsDetail/:str',
        name: 'newsDetail',
        component: () => import(/*webpackChunkName:"name"*/'@/views/newsdetail/newsDetail')
        // component:resolve =>require(['@/views/main/homepage'],resolve)
      },
      {
        path: 'foreAgentDetail/:str',
        name: 'foreAgentDetail',
        component: () => import(/*webpackChunkName:"name"*/'@/views/foredetail/foreAgentDetail')
        // component:resolve =>require(['@/views/main/homepage'],resolve)
      },
      {
        path: 'foreAgent',
        name: 'foreAgent',
        component: () => import(/*webpackChunkName:"name"*/'@/views/foredetail/foreAgent')
        // component:resolve =>require(['@/views/main/homepage'],resolve)
      },
      {
        path: 'serviceDetail/:str',
        name: 'serviceDetail',
        component: () => import(/*webpackChunkName:"name"*/'@/views/servicedetail/serviceDetail')
        // component:resolve =>require(['@/views/main/homepage'],resolve)
      },
    ],
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
